// テキスト関連

// 注意
.c_notice-text {
  margin: $unit-2 0;
  font-size: $font-size-xs;
}

// 更新日時
.c_update-text {
  margin: $unit-2 0;
  color: $gray-color;
  font-size: $font-size-xs;
  text-align: right;
}

// No Data
.c_system-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80%;
  padding: $unit-10 $unit-5;
  span {
    font-weight: bold;
    font-size: $font-size;
    color: darken($gray-color-light, 10);
  }
}