// Pagination
.pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: $unit-5 0;

  .page-item {
    margin: $unit-1 $unit-o;

    span {
      display: inline-block;
      padding: $unit-1;
    }

    i {
      font-size: .85rem;
    }

    a {
      border-radius: $border-radius;
      color: $gray-color-dark;
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 25px;
      min-height: 20px;
      padding: $unit-1 $unit-2;
      line-height: 1.25;

      &:focus,
      &:hover {
        color: $link-color;
      }
    }

    &.disabled {
      a {
        cursor: default;
        opacity: .5;
        pointer-events: none;
      }
    }

    &.active {
      a {
        background: $link-color;
        color: $light-color;
        cursor: default;
        pointer-events: none;
      }
    }

    // カレントしてないアイテムのホバー表示
    &:not(.active) {
      a {
        @include control-transition;

        &:hover {
          background: rgba($link-color, .3);
        }
      }
    }

    &.page-prev,
    &.page-next {
      flex: 1 0 50%;
    }

    &.page-next {
      text-align: right;
    }

    // ページャー内テキスト
    .pagination-text {
      padding: 0;
      font-size: $font-size-xs;

      @media (max-width: $size-sm) {
        display: none;
      }
    }

    .page-item-title {
      margin: 0;
    }

    .page-item-subtitle {
      margin: 0;
      opacity: .5;
    }
  }
}