// o_footer
// フッター
.o_footer {
  background: $footer_bg;
  text-align: center;

  small {
    font-size: $font-size-xs;
    color: $gray-color-dark;
  }

  .o_container-main & {
    margin: $unit-2 (- $unit-2) 0;
    padding: $unit-h $unit-2;
  }
}
