// .c_list
// リストモジュール

// 初期設定、基本スタイルの打ち消しなど
.c_list {
  list-style: none;
  margin: 0;

  li {
    &:first-child {
      margin-top: 0;
    }
  }
}