// .c_card
// オリジナルカード
.c_card {

  // 高さ揃え
  &.c_card-strech {
    height: 100%;
  }

  .card-header {
    * {
      &:not(:first-child) {
        margin-top: $unit-2;
      }
    }
  }

  .card-title {
    font-size: $font-size;
    color: $gray-color;
    border-bottom: 1px solid $gray-color-light;
  }

}

// カード内テキスト
.c_card-text {
  font-size: $font-size-sm;
  background: lighten($gray-color, 40);
  padding: $unit-2 $unit-3;
  margin-top: $unit-4;
}

.c_card-text-status {
  span {
    font-size: $font-size-xs;
    display: block;
  }
}

// カード内テキスト％表示
.c_card-percent {
  margin-bottom: $unit-3;

  @media (max-width: $size-sm) {
    margin: 0;
  }

  .c_card-percent-status {
    color: $gray-color;
    font-size: $font-size-xs;
    margin-left: $unit-5;
  }
}

// カード内％表示（数字）
.c_card-percent-number {
  font-size: 1.5rem;
  font-weight: bold;
}
