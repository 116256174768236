// o_container
// コンテナー

.o_container {
  height: calc(100% - 44px - 35px);
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  .o_wrapper & {
    flex: 1;
  }

  @media print {
    height: 100%;
    overflow: visible;
  }
}

.o_container-inner,
.o_container-main {
  @include scrollbar;
  height: 100%;
  overflow: auto;

  @media print {
    overflow: visible;
  }
}

// 本文
.o_container-main {
  position: relative;
  display: flex;
  flex-direction: column;
  background: lighten($dark-color, 83);
}

.o_container-inner {
  .o_wrapper-login, .o_wrapper-account-mypage & {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}

.o_container-main-body {
  flex: 1;
  font-size: $font-size-sm;

  @include ie {
    flex: 1 0 auto;
  }
}

// topの要素はflexしない
.o_container-main-top {
  .o_container-main & {
    flex: none;
  }
}
