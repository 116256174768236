// c_form
// オリジナルフォーム

// ラベル
.c_form-label {
  .modal & {
    font-size: $font-size-sm;
  }
}

// フォームグループ
.c_form-group {
  margin: 0 0 $unit-4;

  .c_form-datepicker & {
    margin: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
  // アイコン付与時
  &.has-icon {
    display: flex;
    align-items: center;

    .c_form-group-icon {
      flex: none;
      // 真ん中の時は両端にmargin
      &:not(:first-child):not(:last-child) {
        margin: 0 $unit-2;
      }
      // 最初の時は右だけ
      &:first-child {
        margin-right: $unit-2;
      }
      // 最後の時は左だけ
      &:last-child {
        margin-left: $unit-2;
      }
    }
  }

  // フォントサイズ小
  &.c_form-modal {
    font-size: $font-size-xs;
  }

  // 予定入力フォーム
  .c_form-modal-schedule-title {
    font-size: $font-size-lg;
    height: $control-size-lg;
  }

  .form-radio {
    margin: 0;
    cursor: pointer;
  }

  .c_form-schedule-add & {
    margin: 0;
  }

  .c_form-datepicker-date & {
    flex: 1;
  }
}// .c_form-group

// テキスト入力
.c_form-modal-schedule-title,
.c_form-input {
  border: 1px solid $light-color;
  border-bottom: 1px solid $gray-color;
  border-width: 1px;
  box-shadow: none;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;

  &:focus {
    border: 1px solid $primary-color;
    outline: none;
  }
}

// テキストエリア
textarea.c_form-input {
  @include scrollbar;
  overflow-y: auto;
  min-height: 3em;
  max-height: 200px;
  font-size: $font-size-sm;
  resize: vertical;
}

// ボタン型ラジオボタン
.c_form-radio-label {
  display: inline-block;

  @media (max-width: $size-sm) {
    line-height: 2;
  }
  // input非表示
  input {
    display: none;
    // 押下時に色labelに色を付与
    &:checked {
      + label {
        background: $primary-color;
        color: $light-color;
      }

      + .c_form-label-lesson {
        background: $lesson-color;
        color: darken($lesson-color, 55);
      }

      + .c_form-label-consulting {
        background: $consulting-color;
        color: darken($consulting-color, 55);
      }

      + .c_form-label-private {
        background-color: $private-color;
        color: darken($private-color, 55);
      }

      + .c_form-label-self {
        background-color: $self-color;
        color: darken($self-color, 55);
      }
    }
    &:disabled {
      + label {
        cursor: inherit;
        &:hover {
          background: $gray-color-light;
        }
      }
    }
  }
  // label初期設定
  label {
    @include control-transition;
    background: $gray-color-light;
    border-radius: 20px;
    padding: $unit-1 $unit-4;
    color: $light-color;
    cursor: pointer;

    &:hover {
      background: $gray-color;
    }
  }
}
// select要素
.c_form-select {
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;

  // フォントサイズ極小
  // 時間入力セレクトボックス
  &.c_form-select-schedule {
    height: auto;
    padding: $unit-1;
    font-size: $font-size-xs;
    line-height: inherit;

    @media (max-width: $size-sm) {
      font-size: $font-size;
    }
  }
}

.c_form-schedule-add {
  display: flex;
  margin-bottom: $unit-2;

  @media (max-width: $size-sm) {
    flex-direction: column;
  }
}

.c_form-datepicker {
  position: relative;

  .material-icons {
    @include control-transition;
    position: absolute;
    top: $unit-1;
    right: $unit-2;
    z-index: 3;
    font-size: 18px;
    cursor: pointer;

    &:active,
    &:focus,
    &:hover {
      opacity: .8;
    }

    @media (max-width: $size-sm) {
      top: $unit-2;
    }
  }

  .c_form-schedule-add & {
    flex: none;
    margin-right: $unit-5;

    @media (min-width: $size-sm) {
      max-width: 38%;
    }

    @media (max-width: $size-sm) {
      margin-right: 0;
      margin-bottom: $unit-2;
    }
  }
}

.c_form-datepicker-date {
  display: flex;
  flex: 1;
  align-items: center;

  .material-icons {
    margin-right: $unit-h;
  }
}

.c_form-datepicker-input {
  border-radius: $border-radius;
  border: 1px solid $gray-color;
  height: auto;
  padding: $unit-1;
  font-size: $font-size-xs;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;

  @media (max-width: $size-sm) {
    font-size: $font-size;
  }

  &:active,
  &:focus {
    @include control-shadow;
    border-color: $primary-color;
    outline: none;
  }

  .c_form-datepicker & {
    width: 100%;
  }
}

.c_form-term {
  display: flex;
  align-items: center;

  .separate {
    flex: none;
    margin: 0 $unit-1;
  }
}

.c_form-input-group {
  display: flex;
  align-items: center;

  .c_form-input-item {
    flex: 1;
    &:not(:last-child) {
      margin-right: $unit-2;
    }
  }
}

// Invalid
.is-invalid {
  .form-input {
    border-color: $error-color;
  }
}
.c_form-input-invalid {
  display: none;
  margin: $unit-h 0;
  color: $error-color;
  font-size: $font-size-xs;

  .is-invalid & {
    display: block;
  }
}
