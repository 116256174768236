// Modals
// モーダル表示
%has-avatar {
  margin: 0 0 $unit-3;
  display: flex;
  align-items: center;

  &:last-child {
    margin-bottom: 0;
  }

  .avatar {
    width: $unit-16;
    height: $unit-16;
  }
}

.modal {
  @include control-transition;
  align-items: center;
  bottom: 0;
  display: none;
  justify-content: center;
  left: 0;
  opacity: 0;
  overflow: hidden;
  padding: $layout-spacing;
  position: fixed;
  right: 0;
  top: 0;

  &.active,
  &.is-active,
  &:target {
    display: flex;
    opacity: 1;
    z-index: $zindex-4;

    .modal-overlay {
      background: rgba($dark-color, .5); //rgba($bg-color, .75);
      bottom: 0;
      cursor: default;
      display: block;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }

    .modal-container {
      animation: slide-down .2s ease 1;
      max-width: 600px;
      width: 100%;
      max-height: 95vh;
      z-index: $zindex-0;
    }
  }

  &.is-schedule {
    &.active,
    &.is-active,
    &:target {
      .modal-overlay {
        background: rgba($bg-color, .75);
      }
    }
  }

  &.modal-sm {
    .modal-container {
      max-width: $control-width-sm;
      padding: $unit-2;
    }
  }

  &.modal-lg {
    .modal-overlay {
      background: $light-color;
    }

    .modal-container {
      box-shadow: none;
      max-width: $control-width-lg;
    }
  }
}

.modal-container {
  @include shadow-variant(.2rem);
  background: $light-color;
  border-radius: $border-radius;
  display: none;
  flex-direction: column;
  padding: $unit-2 $unit-2 0;

  &.is-active {
    display: flex;
  }

  .modal-header {
    position: relative;
    padding: $unit-2;

    .modal-header-title {
      font-size: $font-size-sm;
      font-weight: bold;
      background: lighten($dark-color, 75);
      padding: $unit-2;
    }
  }

  .modal-body {
    max-height: 80vh;
    overflow-y: hidden;
    padding: $unit-2;
    position: relative;
    font-size: $font-size-sm;

    > * {
      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
    .form-label {
      font-weight: bold;
    }

    .avatar .material-icons {
        font-size: 3.26rem;
    }
  }

  .modal-footer {
    padding: $unit-2;
    text-align: center;
  }
}

.modal-detail-box {
  background: lighten($primary-color, 50);
  padding: $unit-2;
  margin-top: $unit-2;
  line-height: 1.75;
}

.modal-detail-heading {
  font-size: $font-size-sm;
  font-weight: bold;
}

.modal-detail-text {
  span {
    &:not(:last-child) {
      margin-right: $unit-2;
    }
  }
}

.modal-detail {
  dd,
  dt {
    margin: 0;
  }

  dt {
    flex: none;
  }
  dd {
    margin: 0;
    padding-left: $unit-3;
  }

  // アバターあり
  &.has-avatar {
    @extend %has-avatar;
  }

  &.content-definition {
    display: flex;
    align-items: center;

    @media (max-width: $size-sm) {
      display: block;
    }

    dt {
      width: 120px;
      font-size: $font-size-sm;
      text-align: center;

      @media (max-width: $size-sm) {
        width: auto;
        margin: 0 0 $unit-1;
      }
    }

    dd {
      flex: 1;
    }

    .form-input {
      @include scrollbar;
      resize: none;
      font-size: $font-size-sm;
      background: lighten($gray-color, 80);
    }

    .icon-list {
      display: flex;

      @media (max-width: $size-sm) {
        justify-content: space-evenly;
      }

      .item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        line-height: 1;

        &:not(:last-child) {
          margin-right: $unit-5;
        }

        span {
          display: block;
          text-align: center;
        }

        .material-icons {
          font-size: 60px;
        }
      }
    }
    &.content-profile {
      background: lighten($gray-color, 40);

      dd {
        background: $light-color;
      }
    }
  }

  &.avatar-setting {
    display: table;

    label {
      cursor: pointer;
      display: table-cell;
      vertical-align: middle;
    }

    .avatar {
      width: 100px;
      height: 100px;
      background: transparent;

      .material-icons {
        font-size: 100px;
      }
    }

    .segment {
      display: table-cell;
      vertical-align: middle;
      padding: $unit-4;
      > * {
        @media (max-width: $size-sm) {
          font-size: $font-size-sm;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      .btn {
        margin-right: $unit-5;
      }
    }
  }

  &.detail-contents {
    font-size: $font-size-sm;
    padding: $unit-1;
    border: 1px solid lighten($dark-color, 75);
    margin-top: $unit-2;

    .columns {
      margin: 0;
      padding: $unit-2 $unit-o;
    }

    dd {
      padding-left: $unit-1;
    }
    .has-avatar {
      @extend %has-avatar;

      .avatar {
        width: $unit-7;
        height: $unit-7;
        margin-right: $unit-1;
      }

      dd {
        margin: 0;
      }
    }
  }

}
