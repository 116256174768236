// popover
.c_popover {
  @media (max-width: $size-md) {
    position: static;
  }

  .popover-container {
    left: 100%;
    top: 100%;
    width: 230px;
    font-size: $font-size-xs;

    @media (max-width: $size-md) {
      left: 0;
      top: 100%;
      width: auto;
      max-width: 90vw;
    }

    .card-body {
      padding: $unit-2;
      font-weight: normal;
    }
  }

  :focus + .popover-container,
  &:hover .popover-container,
  .popover-container:hover {
    transform: translate(0, -50%) scale(1);

    @media (max-width: $size-md) {
      transform: translate(0, 0) scale(1);
    }
  }
}

.c_popover-btn {
  @include control-transition;
  display: inline-block;
  border: 0;
  padding: 0;
  background: none;
  color: $link-color;
  line-height: 1;
  vertical-align: middle;
  cursor: pointer;
  i {
    font-size: $font-size;
  }

  &:active,
  &:focus,
  &:hover {
    outline: none;
    opacity: .8;
  }
}
