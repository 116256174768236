// c_label
// ラベル表示
.c_label {
  // 基本定義
  display: inline-block;
  border: 1px solid $gray-color;
  padding: 0 $unit-3;
  color: $light-color;
  font-size: $font-size-xs;
  text-align: center;
  line-height: 1.25;

  // 不合格
  &.c_label-failure {
    border-color: $error-color;
    background: $error-color;
  }

  // 合格
  &.c_label-passed {
    border-color: $success-color;
    background: $success-color;
  }

  // 採点待ち
  &.c_label-waiting {
    border-color: $warning-color;
    background: $warning-color;
  }

  // 受験済み
  &.c_label-finished {
    border-color: $gray-color;
    background: $gray-color;
  }

  // 受験可能
  &.c_label-working {
    border-color: lighten($dark-color, 45);
    background: lighten($dark-color, 75);
    color: $dark-color;
  }

  // Excellent or Very good
  &.c_label-excellent {
    border-color: $excellent-color;
    background: $excellent-color;
  }

  // Good
  &.c_label-good {
    border-color: $success-color;
    background: $success-color;
  }

  // Fair
  &.c_label-fair {
    border-color: $fair-color;
    background: $fair-color;
  }

  // Poor
  &.c_label-poor {
    border-color: $error-color;
    background: $error-color;
  }

  // Fail
  &.c_label-fail {
    border-color: $error-color;
    background: $error-color;
  }
}
