// o_header
// ヘッダー

.o_header {
  background: $light-color;
  border-bottom: 1px solid lighten($dark-color, 65);

  .o_header-inner {
    align-items: stretch;
  }

  .o_wrapper & {
    flex: none;
  }
}

// ヘッダーロゴ
.o_header-logo {
  display: flex;
  align-items: center;
  padding: .25rem $unit-2;

  a {
    &:hover {
      text-decoration: none;
    }

    &:focus {
      box-shadow: none;
    }
    // ウインドウ幅840px以上の時はホバーエフェクトを付与
    @media (min-width: $size-md) {
      @include control-transition();

      &:hover {
        opacity: .7;
      }
    }
  }

  .o_header-title {
    a {
      width: 150px;
      display: inline-block;
    }
  }

  // 画像に付与するクラス
  .o_header-logo-image {
    max-width: 100%;
    vertical-align: middle;
  }

  // ヘッダーロゴ内ボタン
  .o_header-nav-btn {
    width: 32px;
    text-align: center;
    line-height: 1;
    margin-right: $unit-2;

    > a {
      &:before {
        @include material-icon('reorder');
      }
    }

    @media (min-width: $size-sm) {
      display: none;
    }

    // 押下時クラスを付与、アイコンを入れ替える
    .is-active {
      &:before {
        display: none;
      }

      .o_header-nav-btn-open {
        display: none;
      }

      .o_header-nav-btn-close {
        display: block;
      }
    }

    // 閉じるボタン
    .o_header-nav-btn-close {
      display: none;
      position: relative;

      &:before {
        @include material-icon('close');
      }
    }
  }

  // ヘッダーロゴ見出し
  .o_header-title {
    font-size: $font-size-sm;
  }
}

// ユーティリティー
.o_header-utility {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: $size-sm) {
    width: auto;
  }
}

// 通知
.o_header-notice {
  margin-right: $unit-10;

  @media (max-width: $size-sm) {
    display: none;
    margin-right: $unit-5;
  }
}

// アバター画像
.o_header-avatar {
  background: $primary-color;
  margin-right: $unit-1;
  width: 26px;
  height: 26px;

  .material-icons {
    font-size: 26px;
  }
}

// ユーザー名
.o_header-user {
  @include text-ellipsis; // 最大文字数超えたら丸める
  min-width: 5em; // 最低5文字分の幅を担保
  max-width: 15em; // 最大15文字分の幅を定義
  margin-right: $unit-1;
  font-size: $font-size-xs;

  @media (max-width: $size-sm) {
    display: none;
  }
}

// レポートリスト
.o_header-report {
  width: 210px;
  font-size: $font-size-xs;

  // li要素
  li {
    line-height: 1.6;

    &:first-child {
      margin-top: 0;
    }

    &:not(:last-child) {
      border-bottom: 1px solid $gray-color;
    }
  }

  // 日付
  .date {
    font-size: .5rem;
    color: $gray-color;
  }

  // 見出し
  .title {
    @include text-ellipsis();
  }

  // リンクテキスト
  .link {
    @include control-transition();
    display: inline-flex;
    align-items: center;
    color: $link-color;

    &:before {
      @include material-icon('chevron_right', inherit);
    }

    &:hover {
      text-decoration: none;
    }

    // ウインドウ幅840px以上の時はホバーエフェクトを付与
    @media (min-width: $size-md) {
      &:hover {
        opacity: .7;
      }
    }

    // リンクテキスト内アイコン
    .icon {
      font-size: inherit;
    }
  }
}
