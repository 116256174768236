// Typography
// Headings
h1,
h2,
h3,
h4,
h5,
h6 {
  color: inherit;
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: .5em;
  margin-top: 0;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: bold;
}
h1,
.h1 {
  font-size: 2rem;
}
h2,
.h2 {
  font-size: 1.6rem;
}
h3,
.h3 {
  font-size: 1.4rem;
}
h4,
.h4 {
  font-size: 1.2rem;
}
h5,
.h5 {
  font-size: 1rem;
}
h6,
.h6 {
  font-size: .8rem;
}

// Paragraphs
p {
  margin: 0 0 $line-height;
}

// Semantic text elements
a,
ins,
u {
  text-decoration-skip: ink edges;
}

abbr[title] {
  border-bottom: $border-width dotted;
  cursor: help;
  text-decoration: none;
}

kbd {
  @include label-base();
  @include label-variant($light-color, $dark-color);
  font-size: $font-size-sm;
}

mark {
  @include label-variant($body-font-color, $highlight-color);
  border-radius: $border-radius;
  padding: .05rem;
}

// Blockquote
blockquote {
  border-left: $border-width-lg solid $border-color;
  margin-left: 0;
  padding: $unit-2 $unit-4;

  p:last-child {
    margin-bottom: 0;
  }
}

// Lists
ul,
ol {
  margin: $unit-4 0 $unit-4 $unit-4;
  padding: 0;

  ul,
  ol {
    margin: $unit-4 0 $unit-4 $unit-4;
  }

  li {
    margin-top: 0;
  }
}

ul {
  list-style: disc inside;

  ul {
    list-style-type: circle;
  }
}

ol {
  list-style: decimal inside;

  ol {
    list-style-type: lower-alpha;
  }
}

dl {
  dt {
    font-weight: bold;
  }
  dd {
    margin: $unit-2 0 $unit-4 0;
  }
}
