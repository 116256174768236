// error

@import 'spectre';
@import 'outline';


.m_error-wrap {
	width: $size-md;
    padding: 1% 0 0;
    margin: auto;
    padding: 100px 0 0;

	.c_btn-group {
		justify-content: left;
	}

	.m_error-title {
		font-weight: normal;
	}

	h3.m_error-title {
		padding: 50px 0 0;
	}


}

@media (max-width: $size-md) {
	.m_error-wrap {
		width: $size-sm;
	}
}

@media (max-width: $size-sm) {
	.m_error-wrap {
		padding: 20px 0 0;
		width: auto;

		h3.m_error-title {
			padding: 20px 0 0;
		}
	}
}
