// トーストUI
$toast_basic_clor: #ccc;

// トーストUI基本定義
.c_toast {
  @include control-transition($motion: ease-in-out);
  @include shadow-variant(0);
  max-width: 300px;
  border: 2px solid $toast_basic_clor;
  padding: $unit-2;
  background: $light-color;
  font-size: $font-size-sm;
  transform: translateY(-5px);

  .c_toast-important-text {
    text-align: center;
    margin: 0;
  }

  .c_toast-important-timer {
    display: block;
    color: $important-color;
    font-size: 1.5rem;
    font-weight: bold;
  }

  .c_btn {
    display: block;
    width: 100%;
  }
}

// トーストの外枠
.c_toast-wrapper {
  @include control-transition($motion: ease-in-out);
  position: fixed;
  top: 3rem;
  right: $unit-5;
  z-index: 2;

  .c_toast {
    &:not(:last-child) {
      margin-bottom: $unit-3;
    }
  }
}

// トースト内閉じるボタン
.c_toast-close {
  @include control-transition;
  float: right;
  background: none;
  padding: 0;
  border: 1px solid transparent;
  border-radius: 50%;
  margin: 0 0 $unit-1 $unit-1;
  font-size: $font-size;
  cursor: pointer;

  &:active,
  &:focus,
  &:hover {
    border-color: #ccc;
  }
}

// カウントダウントースト
.c_toast-important {
  border-color: $important-color;
  color: $important-color;

  .c_toast-close {
    color: $important-color;

    &:hover {
      border-color: $important-color;
    }
  }

}

// 注意トースト
.c_toast-warning {
  border-color: $warning-color;
  color: $warning-color;

  .c_toast-close {
    color: $warning-color;

    &:hover {
      border-color: $warning-color;
    }
  }
}

// 完了トースト
.c_toast-success {
  border-color: $success-color;
  color: $success-color;

  .c_toast-close {
    color: $success-color;

    &:hover {
      border-color: $success-color;
    }
  }
}
