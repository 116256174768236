// o_sidenav
// サイドナビレイアウト

.o_sidenav {
  height: 100%;
  background: $dark-color;

  @media (min-width: $size-lg) {
    width: 210px;
  }

  @media (max-width: $size-lg) {
    position: relative;
    width: 37px;
    overflow: visible;
  }

  @media (max-width: $size-sm) {
    width: 0;
  }

  &.is-close {
    width: 37px;

    @media (max-width: $size-sm) {
      width: 0;
    }
  }
}

.o_sidenav-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;

  @media (max-width: $size-lg) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: $dark-color;
    z-index: 3;
  }

  @media (max-width: $size-sm) {
    overflow: auto;
  }

  // 閉じた時の挙動
  .is-close & {
    @media (max-width: $size-lg) {
      width: 210px;
      box-shadow: 0 4px 10px rgba($dark-color, .3);
    }
  }

  a {
    &:active,
    &:focus,
    &:hover {
      box-shadow: none;
    }
  }
}

.o_sidenav-overlay {
  @media (min-width: $size-lg) {
    display: none;
  }

  @media (max-width: $size-md) {
    @include control-transition(opacity);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: transparent;
    z-index: 2;
    width: 100%;
    opacity: 0;
    visibility: hidden;
  }

  @media (max-width: $size-sm) {
    background: rgba($dark-color, .3);
  }

  // 閉じた時オーバーレイ表示
  .is-close & {
    @media (max-width: $size-lg) {
      width: 100vw;
      opacity: 1;
      visibility: visible;
    }
  }
}

.o_sidenav-list {
  @include scrollbar;
  list-style: none;
  flex: 1;
  margin: 0 0 $unit-3;

  @media (max-width: $size-md) {
    overflow: auto;
  }

  .o_sidenav-list-item {
    position: relative;
    margin: 0;

    a {
      @include control-transition;
      display: flex;
      align-items: center;
      padding: $unit-2;
      color: lighten($dark-color, 75);
      i {
        color: lighten($dark-color, 55);
      }

      &:active,
      &:focus,
      &:hover {
        color: lighten($dark-color, 80);
        background: darken($dark-color, 10);
      }
    }

    // カレント時
    &.is-active {
      a {
        color: $light-color;
        background: $primary-color;
        i {
          color: $light-color;
        }
      }
    }
  }

  // リスト内アイコン
  .o_sidenav-list-icon {
    font-size: 1rem;
  }

  // リスト内テキスト
  .o_sidenav-list-text {
    @include text-ellipsis;
    @include control-transition;
    font-size: .65rem;
    width: 100%;
    margin-left: $unit-2;
    @media (max-width: $size-lg) {
      width: 0;
      opacity: 0;
    }
    @media (max-width: $size-sm) {
      font-size: .85rem;
    }
  }

  // 閉じたときの挙動
  .is-close & {
    // アイコン
    .o_sidenav-list-icon {
      @media (min-width: $size-lg) {
        margin: 0;
      }
    }

    // テキスト
    .o_sidenav-list-text {
      @media (max-width: $size-lg) {
        width: 100%;
        opacity: 1;
      }
      @media (min-width: $size-lg) {
        width: 0;
        opacity: 0;
      }
    }
  }
}

.o_sidenav-list-submenu {
  margin: 0;
  list-style: none;
  font-size: $font-size-xs;

  // 下層メニューホバー時
  .has-submenu & {
    @media (min-width: $size-md) {
      position: absolute;
      top: 0;
      left: 100%;
      z-index: 2;
      min-width: 150px;
      padding: $unit-1;
      opacity: 0;
      visibility: hidden;
      background: lighten($dark-color, 10);

      li {
        margin: 0;

        a {
          color: lighten($dark-color, 70);

          &:active,
          &:focus,
          &:hover {
            color: $light-color;
            background: transparent;
          }
        }
      }
    }

    @media (max-width: $size-md) {
      display: none;
    }
  }

  .has-submenu:hover & {
    @media (min-width: $size-sm) {
      visibility: visible;
      opacity: 1;
    }
  }
  // カレント時は常時表示
  .has-submenu.is-active & {
    min-width: auto;
    box-shadow: none;
    font-size: $font-size-xs;
    position: static;
    opacity: 1;
    visibility: visible;
    padding: 0;
    transform: none;

    li {
      margin: 0;
      padding: 0;

      a {
        @include text-ellipsis;
        display: flex;
        align-items: center;
        padding: $unit-2 $unit-2 $unit-2 $unit-4;
        color: lighten($dark-color, 60);
        background: lighten($dark-color, 5);

        &:active,
        &:focus,
        &:hover {
          color: $light-color;
        }
      }

      &.is-active {

        a {
          color: $light-color;
          font-weight: bold;
        }
      }
    }

    @media (max-width: $size-lg) {
      display: none;
    }
  }

  .has-submenu.is-active:hover & {
    transform: none;
  }

  .is-close .has-submenu.is-active & {
    display: none;
  }
}

// メニューを閉じるボタン
.o_sidenav-btn {
  flex: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  height: 40px;
  background: transparent;
  border: 0;
  padding: $unit-h $unit-2;
  color: lighten($dark-color, 70);
  font-size: $font-size-xs;
  text-align: left;
  cursor: pointer;

  &:active,
  &:focus,
  &:hover {
    color: lighten($dark-color, 70);
    opacity: .8;
  }

  @media (max-width: $size-lg) {
    padding: $unit-h $unit-h $unit-h $unit-2;
  }

  @media (max-width: $size-sm) {
    display: none;
  }

  // 矢印アイコン
  &:before {
    @include material-icon('keyboard_arrow_left');
    @include control-transition;
    font-size: 1rem;
    margin-right: $unit-3;

    @media (max-width: $size-lg) {
      transform: rotateY(180deg);
    }
  }

  // ボタン内テキスト
  .o_sidenav-btn-text {
    @include text-ellipsis;
    width: 100%;
  }

  // 閉じた時の挙動
  .is-close & {
    // padding: $unit-5;

    // 矢印ボタン
    &:before {
      position: static;
      transform: rotateY(180deg);

      @media (max-width: $size-lg) {
        transform: rotateY(0);
      }
      @include ie {
        position: absolute;
      }
    }
    // ボタン内テキスト
    .o_sidenav-btn-text {
      width: 0;
      opacity: 0;
      @at-root .is-modal &{
        width: auto;
        opacity: 1;
      }
    }
  }
}
