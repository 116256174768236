// .c_table
// テーブルのスタイル
.c_table-wrapper {
  @include scrollbar;
  width: 100%;
  overflow: auto;
}

//基本形
.c_table-list {
  width: 100%;
  table-layout: fixed;

  td,
  th {
    padding: $unit-2;
    font-size: $font-size-xs;
    word-wrap: break-word;
  }

  thead {
    // theadのセルに幅をつけることでテーブルの幅を定義
    // 日付
    .cell-date {
      width: 200px;

      @media (max-width: $size-md) {
        width: 130px;
      }
    }

    // 期間
    .cell-period {
      width: 180px;
    }

    // プラン
    .cell-plan {
      width: 180px;
    }

    // 企業名
    .cell-company-name {
      width: 200px;
    }

    // ID
    .cell-id {
      width: 80px;
      @media (max-width: $size-sm) {
        width: 70px;
      }
    }

    // 名前
    .cell-name {
      width: 160px;
      @media (max-width: $size-md) {
        width: 130px;
      }
    }

    // メールアドレス
    .cell-email {
      width: auto;
    }

    // 成果保証条件
    .cell-conditions {
      width: 190px;
      text-align: center;
    }

    // テスト名
    .cell-testname {
      @media (max-width: $size-lg) {
        width: 150px;
      }
    }

    // ステータス
    .cell-status {
      width: 120px;
    }

    // 受験日時
    .cell-examtime {
      @media (max-width: $size-lg) {
        width: 120px;
      }
    }

    // スコア
    .cell-cefrj,
    .cell-score {
      width: 80px;
    }

    // ユーティリティ
    .cell-utility {
      width: 100px;
      text-align: center;
    }

    .cell-test {
      width: 190px;
      text-align: center;
    }

    // 自己紹介
    .cell-introduction {
      width: auto;

      @media (max-width: $size-sm) {
        width: 200px;
      }
    }

    // 教材レベル
    // 教材Week
    .cell-material-week,
    .cell-material-level {
      width: 60px;
    }

  }// thead

  tbody {
    background: $light-color;

    // 名前
    .cell-name {
      .item {
        display: flex;
        align-items: center;
      }

      .avatar {
        flex: none;
        margin-right: $unit-2;
        @include avatar-base($unit-7);
      }

      .name {
        word-break: break-all;
      }
    }

    // メールアドレス
    .cell-email {
      word-break: break-all;
    }

    // 日時
    .cell-date {
      .reservation-date {
        display: inline;
      }

      .reservation-time {
        display: inline;
        @media (min-width: $size-md) {
          margin-left: $unit-1;
        }
      }
    }

    .cell-examtime {
      div[class$="-date"],
      div[class$="-hour"] {
        display: inline;
      }
    }

    .cell-status {
      text-align: center;

      .c_label {
        width: 4rem;
        margin: 0 auto;
      }
    }

    .cell-utility {
      text-align: center;
    }

    // 教材レベル
    // 教材Week
    .cell-material-week,
    .cell-material-level {
      text-align: center;
    }

    // 教材タイトル
    .cell-material-title {
      word-break: break-word;
      font-size: $font-size-sm;
    }

    // 結果無い時
    .result-waiting,
    .not-taken {
      &:before {
        content: 'ー';
        text-align: center;
        display: flex;
        flex-direction: column;
        color: lighten($dark-color, 60);
      }
    }

    // テスト結果レベル
    .cell-cefrj,
    .cell-score {
      .level-detail {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        // 赤点
        &.result-fail {
          color: $error-color;
        }

        // 合格点
        &.result-pass {
          color: $success-color;
        }

        // 合格点
        &.result-excellent {
          color: $excellent-color;
        }

        // ランク表記
        .level {
          font-size: 1.2rem;
          font-weight: bold;
          line-height: 1;
          margin-right: $unit-1;
        }

        // パーセンテージ表記
        .percent {
          font-size: $font-size;
          small {
            font-size: $font-size-xs;
          }
        }
      }
    }

    .cell-introduction {
      @include text-ellipsis;
    }

    .c_btn {
      @include btn-sm;
    }

    .data-items {
      display: flex;
      justify-content: space-between;
    }

    .data-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1;
      text-align: center;

      .item-caption {
        font-size: $font-size-xs;
      }

      .item-result {
        font-size: $font-size-lg;
        font-weight: bold;

        // 文字色（他にあれば追加）
        &.is-error {
          color: $error-color;
        }
      }
    }

  }// tbody
}// table
