// Calendars
.calendar {
  border: $border-width solid $border-color;
  display: block;
  min-width: 280px;

  // 月次カレンダー
  &.calendar-monthly {
    min-width: auto;
  }

  // 週次カレンダー
  &.calendar-weekly {
    min-width: auto;
    border: 0;
  }

  // 日次カレンダー
  &.calendar-daily {
    min-width: auto;
    border: none;

    @media (max-width: $size-sm) {
      padding-bottom: 10px;
    }
  }
}

.calendar-nav {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.8rem;
  box-sizing: content-box;
  background: $bg-color-light;
  padding: $layout-spacing;

  @media (max-width: $size-md) {
    flex-direction: column-reverse;
    height: auto;
  }

  @at-root .calendar-monthly &{
    @media (max-width: $size-sm) {
      justify-content: flex-start;
    }
  }

  @at-root .calendar-daily &{
    position: relative;
    justify-content: center;
    border: 1px solid lighten($border-color, 5);
    border-bottom: none;
    margin-left: 35px;
  }

  @at-root .calendar-weekly &{
    position: relative;
    justify-content: center;
    border: 1px solid lighten($border-color, 5);
    border-bottom: none;
    margin-left: 35px;
  }

  .calendar-nav-date {
    font-size: $font-size;
    font-weight: bold;
  }

}

// カレンダーヘッダー部分
.calendar-header {
  background: $bg-color-light;
  color: $gray-color-dark;
  font-size: $font-size-sm;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: $layout-spacing 0;
  border: 1px solid lighten($border-color, 5);
  padding-top: 0;
  padding-bottom: 0;

  @at-root .calendar-monthly &{
    border: none;
  }

  @at-root .calendar-daily &{
    border-bottom: 0;
    margin-left: 35px;
  }

  @at-root .calendar-weekly &{
    border-bottom: 0;
    margin-left: 35px;
  }

  .calendar-date {
    @include text-ellipsis;
    font-size: .5rem;
    height: auto;

    @at-root .calendar-monthly &{
      border-right: none;
      height: 1.25rem;
      font-size: $font-size-xs;
    }

    @at-root .calendar-daily &{
      flex: 1;
      max-width: none;
    }

    &:last-child {
      border-right: none;
    }

    // 今日の表示位置のセルを青色
    &.is-today {
      background: $today-secondary-color;
      color: $today-primary-color;
    }

    .calendar-day {
      font-size: 1.5rem;
      line-height: 1;
      @at-root .calendar-weekly &{
        @media (max-width: $size-sm) {
          font-size: 1rem;
        }
      }
    }

  }
}

.calendar-body {
  color: $gray-color-dark;
  padding: 0;
  border-left: 1px solid lighten($border-color, 5);
  border-right: 1px solid lighten($border-color, 5);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  justify-content: flex-start;
  align-items: stretch;

  @at-root .calendar-monthly &{
    border-left: none;
    border-right: none;
  }

  @at-root .calendar-daily &{
    flex-wrap: nowrap;
    margin-left: 35px;
  }

  @at-root .calendar-weekly &{
    flex-wrap: nowrap;
    margin-left: 35px;
  }
  // 日次ヘッダー
  .calendar-weekly-hours {
    flex: none;
    width: 50px;
    color: $gray-color;
    font-size: $font-size-sm;
    line-height: 1.1;
    text-align: right;

    .calendar-weekly-hour {
      padding: $unit-2;
      border-right: 1px solid $gray-color-light;
    }
  }
}

%data-disabled {
  cursor: default;
  opacity: .25;
  pointer-events: none;
}

// 一日分のセル
.calendar-date {
  border: none;
  border-bottom: $border-width solid lighten($border-color, 5);
  border-right: $border-width solid lighten($border-color, 5);
  display: flex;
  flex-direction: column;
  padding: 0;
  position: relative;
  flex: 0 0 14.28%; // 7 calendar-items each row
  max-width: 14.28%;

  .calendar-body & {
    background: $light-color;
  }

  @at-root .calendar-monthly &{
    height: 5rem;

    @media (max-width: $size-md) {
      height: 4.75rem;
    }
  }

  @at-root .calendar-daily &{
    flex: 1;
    max-width: none;
  }

  &:nth-child(7n) {
    border-right: none;
  }

  &:nth-last-child(-n+7) {
    border-bottom: none;
  }

  &:first-child {
    // 月曜日のセルに付与されているdata属性だけ表示する
    .data-hour {
      &[data-hour$=":30"],
      &[data-hour$=":00"] {
        &::after {
          display: block;
        }
      }
    }
  }

  &:last-child {
    border-right: none;
  }

  &.is-today {
    background: lighten($success-color, 60);
  }
  // 時間セルのスタイル定義
  .data-hour {
    position: relative;
    height: 25px;
    padding: $unit-h $unit-1;
    border-top: 1px dashed $border-color;

    &[data-hour$=":00"] {
      border-top: 1px solid $border-color;
    }

    &.day-end {
      height: 0;
      padding: 0;
    }
    // 時間表記：date属性内の数字を使用する
    &::after {
      display: none;
      content: attr(data-hour);
      position: absolute;
      right: calc(100% + 5px);
      bottom: calc(100% - 7px);
      width: 50px;
      font-size: $font-size-xs;
      line-height: 1.1;
      text-align: right;
      word-break: break-word;
    }
    // 時間タグ
    .date-time {
      position: relative;
      padding: 0 $unit-h;
      color: $gray-color;
      font-size: .5rem;
      z-index: 2;
    }
  }

  .date-item {
    @include control-transition;
    appearance: none;
    background: transparent;
    border: $border-width solid transparent;
    color: $gray-color-dark;
    outline: none;
    padding: $unit-h;
    position: relative;
    vertical-align: middle;
    align-self: flex-end;
    display: inline-block;
    width: auto;
    max-width: 100%;
    height: auto;
    margin: 0;
    font-size: $font-size-xs;
    line-height: 1;

    @at-root .calendar-monthly &{
      height: auto;
      margin: 0;
      line-height: 1;
      font-size: $font-size-sm;
    }

    @at-root .calendar-weekly &{
      @include text-ellipsis;
    }

    @at-root .calendar-daily &{
      @include text-ellipsis;
    }

    &:focus {
      background: lighten($warning-color, 10%);
      box-shadow: none;
    }

    &.badge {
      &::after {
        position: absolute;
        top: 3px;
        right: 3px;
        transform: translate(50%, -50%);
      }
    }
  }// date-item

  .date-time,
  .date-title,
  .date-day {
    @include text-ellipsis;
    padding: $unit-h;
    color: $gray-color-dark;
    line-height: 1;
  }

  .date-day {
    display: flex;
    align-self: flex-end;
    margin: $unit-h $unit-h 0 0;
  }

  &.disabled {
    .calendar-event,
    .date-time,
    .date-title,
    .date-day,
    .date-item,
    .c_badge {
      @extend %data-disabled;
    }
  }
}

%data-scheduled {
  @include control-transition;
  position: absolute;
  left: 1%;
  width: 98%;
  border-radius: $border-radius;
  padding: $unit-h 0;
  font-size: $font-size-xs;
  z-index: 2;
}

.schedule-data {
  @at-root .calendar-weekly &{
    @extend %data-scheduled;

    @media (max-width: $size-sm) {
      font-size: 10px;
    }
  }

  @at-root .calendar-daily &{
    @extend %data-scheduled;
  }

  // private
  &.data-private {
    background: $private-color;

    @at-root .calendar-monthly &{
      background: $private-color;
    }

    &:active,
    &:focus,
    &:hover {
      background: lighten($private-color, 5);
    }

    // private非表示ボタン押下時
    &.is-hidden {
      background: transparent;

      > * {
        opacity: 0;
      }
    }
  }

  &.data-consulting,
  &.data-lesson {
    &:before {
      @include material-icon('lock', inherit);
      vertical-align: bottom;
      margin-right: $unit-h;
      position: absolute;
      right: 0;
      bottom: 3px;
      @at-root .is-admin &{
        display: none;
      }
    }

    @at-root .calendar-monthly &{
      @media (max-width: $size-sm) {
        overflow: visible;
      }

      &:before {
        position: inherit;
        width: auto;
        height: auto;
        margin-right: $unit-h;
      }
    }
  }

  // lesson
  &.data-lesson {
    background: $lesson-color;

    &:before {
      color: darken($lesson-color, 40);
    }

    &:active,
    &:focus,
    &:hover {
      background: lighten($lesson-color, 5);
    }
  }

  // Consulting session as consulting
  &.data-consulting {
    background: $consulting-color;

    &:before {
      color: darken($consulting-color, 40);
    }

    &:active,
    &:focus,
    &:hover {
      background: lighten($consulting-color, 5);
    }
  }

  // self as Self learning
  &.data-self {
    background: $self-color;

    &:active,
    &:focus,
    &:hover {
      background: lighten($self-color, 5);
    }

    @at-root .calendar-monthly &{
      &:before {
        background: darken($self-color, 10);
      }
    }
  }
}

.calendar-events {
  @include scrollbar;
  flex-grow: 1;
  line-height: 1;
  overflow-y: auto;
  padding: $layout-spacing-sm;

  .calendar-event {
    @include control-transition;
    display: flex;
    align-items: center;
    padding: 3px 4px;
    margin: $unit-h auto;
    color: $dark-color;
    font-size: $font-size-xs;

    span {
      @include text-ellipsis;
    }

    &:before {
      flex: none;
      position: relative;
      top: 2px;
      display: inline-block;
      width: 8px;
      height: 8px;
      margin-right: $unit-1;
      vertical-align: middle;
    }

    &.is-hidden {
      display: none;
    }

    &:hover {
      opacity: .8;
    }

    &.is-active {
      color: $dark-color;
      background: lighten($warning-color, 10%);
      box-shadow: none;
    }

    &:focus {
      background: lighten($warning-color, 10%);
      box-shadow: none;
    }

    &:disabled {
      @extend %data-disabled;
    }

    @media (max-width: $size-md) {
      font-size: $font-size-xs;
    }

    // 横幅小さい時は丸アイコン化
    @media (max-width: $size-sm) {
      width: .7rem;
      height: .7rem;
      border-radius: 50%;
      padding: 0;
      margin: $unit-1 auto $unit-1 0;

      &:last-child {
        margin-bottom: 0;
      }

      > span {
        display: none;
      }
    }
  }

  .calendar-event-other {
    display: inline-block;
    margin-top: $unit-1;
    color: $gray-color;
    cursor: pointer;

    @media (max-width: $size-sm) {
      width: auto;
      height: auto;
      border-radius: 0;
      overflow: visible;
      font-size: $font-size-xs;
    }

    &:before {
      display: none;
    }

    span {
      @media (max-width: $size-sm) {
        display: inline;
      }
    }
  }
}
