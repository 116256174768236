// .c_dropdown
// ドロップダウンメニュー
.c_dropdown {
  position: relative;

  // .c_dropdownにopenを付与したらが表示
  &[open] {
    .c_dropdown-body {
      visibility: visible;
      opacity: 1;
    }
  }

  // チェックボックス
  // チェックボックスにチェックを入れたら
  .c_dropdown-checkbox {
    display: none;

    &:checked ~  {
      // アイコンを回転する
      .c_dropdown-header[for="dropdown-setting"] {
        &:after,
        .c_dropdown-icon {
          transform: rotateX(180deg);
        }
      }
      // bodyを表示する
      .c_dropdown-body {
        visibility: visible;
        opacity: 1;
        padding: $unit-1;
      }
    }
  }

  // ヘッダー内アコーディオン
  .c_dropdown-header {
    position: relative;
    display: block;
    color: inherit;
    line-height: 1;
    cursor: pointer;

    // 通知アイコン
    .badge {
      position: absolute;
      top: .25rem;
      right: -.5rem;
      z-index: 2;
    }
  }

  .c_dropdown-header[for="dropdown-setting"] {
    display: flex;
    align-items: center;

    &:after {
      @include material-icon('keyboard_arrow_down', 16px);
      @include control-transition;
    }
  }

  // アコーディオン本文
  .c_dropdown-body {
    @include control-transition;
    position: absolute;
    right: 0;
    top: calc(100% + .3rem);
    min-width: 150px;
    max-height: 80vh; // ブラウザの高さが低いとき、画面に合わせて縮小する
    background: $light-color;
    box-shadow: 0 0 1px rgba($gray-color, .4);
    border-radius: $border-radius;
    padding: 0 $unit-1;
    color: $body-font-color;
    visibility: hidden;
    opacity: 0;
    overflow: auto; // ブラウザの高さが低いとき、スクロールできるように定義
    z-index: 5;
    font-size: $font-size-xs;

    a {
      display: block;
      padding: $unit-2 $unit-1;

      &:hover {
        background: lighten($button-primary-color-dark, 45);
      }
    }
  }

}
