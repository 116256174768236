// o-wrapper
// ラッパー

.o_wrapper {
  // ウインドウ幅に合わせるためflex化
  display: flex;
  flex-direction: column;
  height: 100%;
  // background: $dark-color;
  -webkit-overflow-scrolling: touch;

  &.o_wrapper-login {
    background: $light-color;
  }

  &.o_wrapper-account-mypage{
    background: $light-color;
  }
}
