// Animations
@keyframes loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateY(-$unit-8);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

// css3アニメーションのmixin
@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

//　フェードスライドイン
@keyframes fadeIn_slide_top {
  0% {
    opacity: 0;
    transform: translateY(-5px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

// 接続時の句点上下アニメーション
@keyframes m_connecting {
  0% {
    opacity: 0;
    transform: translateX(0);
  }

  50% {
    opacity: 1;
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(50px);
  }
}

@keyframes spiner {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
