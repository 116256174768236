// .c_alert

// 枠付きアラート
.c_alert-frame {
  background: lighten($error-color, 50%);
  border: 1px solid $error-color;
  border-radius: $border-radius;
  padding: $unit-1 $unit-2;
  color: $error-color;
  a {
    color: $error-color;
  }
}
