// ボタン用
@mixin btn-sm {
  font-size: $font-size-xs;
  height: $control-size-sm;
  padding: $control-padding-y-sm $control-padding-x-sm;
}

@mixin btn-lg {
  font-size: $font-size-lg;
  height: $control-size-lg;
  padding: $control-padding-y-lg $control-padding-x-lg;
}

@mixin btn-block {
  display: block;
  width: 100%;
}
