// スクロールバースタイルのmixin

// 使い方
// スクロールバーのスタイルを変えたい要素に
// .hoge {
//   @include scrollbar;
//   もしくは、
//   @include scrollbar();
//
//   幅や高さを変えたい場合、
//   @include scrollbar(4px,4px);
//
// }

@mixin scrollbar($width: 2px, $height: 2px) {
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    width: $width;
    height: $height;
    // スクロールバーの背景色
    &-track {
      background: lighten($dark-color, 75);//$light-color;
    }
    // スクロールバーの色
    &-thumb {
      background: lighten($primary-color, 15);
    }
  }
}
