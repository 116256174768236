// Base
*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  height: 100%;
  box-sizing: border-box;
  font-size: $html-font-size;
  line-height: $html-line-height;
  -webkit-tap-highlight-color: transparent;

  @media (max-width: $size-sm) {
    font-size: calc(100vw / 26);
  }

  @media (max-width: $size-xxs) {
    font-size: calc(100vw / 20);
  }
}

body {
  height: 100%;
  background: $body-bg;
  color: $body-font-color;
  font-family: $body-font-family;
  font-size: $font-size;
  overflow-x: hidden;
  text-rendering: optimizeLegibility;

  &.is-modal {
    @media (max-width: $size-sm) {
      overflow: hidden;
    }
  }
}

a {
  color: $link-color;
  outline: none;
  text-decoration: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;

  &:focus,
  &:hover,
  &:active,
  &.active {
    color: $link-color-dark;
  }
}

area,
button,
[role="button"],
input:not([type=range]),
label,
select,
summary,
textarea {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

input {
  [type="file"] {
    :disabled {
      cursor: not-allowed;
    }
  }
}

// @extend material-icons;
%material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga';
}
