// c_badge
// オリジナルバッジ
.c_badge {

  // バッジ定義
  &:before {
    display: inline-flex;
    width: 10px;
    height: 10px;
    background: $gray-color-light;
    border-radius: 50%;
    content: "";
  }

  // Lesson
  &.c_badge-lesson:before {
    background: darken($lesson-color, 30);
  }

  // Consulting
  &.c_badge-consulting:before {
    background: darken($consulting-color, 30);
  }

  // Private
  &.c_badge-private:before {
    background: darken($private-color, 30);
  }

  // Self Learning
  &.c_badge-self:before {
    background: darken($self-color, 30);
  }
}
