// メール本文の共通スタイル
@mixin inboxview_textarea {
  background: $light-color;
  pre {
    margin: 0;
    padding: $unit-2 $unit-3;
    font-family: $body-font-family;
    white-space: pre-wrap;
    word-break: break-word;

    @media (max-width: $size-sm) {
      font-size: .7rem;
      padding: $unit-3 0;
    }

    // メール内のタグのスタイル
    // 定義したい要素は書き足していく。
    blockquote {
      background: rgba($gray-color-light, .3);
      border-color: $gray-color-light;
      color: $gray-color-dark;
      font-style: italic;
    }

    // メール内の最後のタグ要素はmargin消す
    * {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
