// 変数一覧
// 色の変更、ボタンの種類の追加を考慮して/_custom/に格納

// Core variables
$version: "0.5.1";

// Core features
$rtl: false !default;

// Core colors
$primary-color: #633da7 !default;
$primary-color-dark: darken($primary-color, 8%) !default;
$primary-color-light: lighten($primary-color, 5%) !default;
$secondary-color: lighten($primary-color, 37.5%) !default;
$secondary-color-dark: darken($secondary-color, 3%) !default;
$secondary-color-light: lighten($secondary-color, 3%) !default;

// Gray colors
$dark-color: #252525 !default;
$light-color: #fff !default;
$gray-color: lighten($dark-color, 40%) !default;
$gray-color-dark: darken($gray-color, 25%) !default;
$gray-color-light: lighten($gray-color, 20%) !default;
$border-color: lighten($dark-color, 65%) !default;
$border-color-dark: darken($border-color, 10%) !default;
$bg-color: lighten($dark-color, 70%) !default;
$bg-color-dark: darken($bg-color, 3%) !default;
$bg-color-light: lighten($dark-color, 80%) !default;

// Control colors
$excellent-color: #0093d6 !default;
$success-color: #02adc1 !default;
$warning-color: #ffb100 !default;
$error-color: #e91e63 !default;
$important-color: #e91e63 !default;
$fair-color: #cae5cd !default;

// Link colors
$link-color: #4c5fca !default;
$link-color-dark: darken($link-color, 20) !default;

// Button colors
$button-primary-color: $link-color;
$button-primary-color-dark: darken($button-primary-color, 3%) !default;
$button-primary-color-light: lighten($button-primary-color, 3%) !default;

// today-color
$today-primary-color: #191970 !default;
$today-primary-color-dark: darken($today-primary-color, 3%) !default;
$today-primary-color-light: lighten($today-primary-color, 3%) !default;
$today-secondary-color: lighten($today-primary-color, 60.5%) !default;
$today-secondary-color-dark: darken($today-secondary-color, 3%) !default;
$today-secondary-color-light: lighten($today-secondary-color, 3%) !default;

// Other colors
$code-color: #e06870 !default;
$highlight-color: #ffe9b3 !default;
$body-bg: $bg-color-light !default;
$body-font-color: $dark-color !default;
$score-color: #f75b00;

// メールリストのホバー・カレント表示の背景色
$maillist-hover: rgba($secondary-color-light, .5);
$maillist-tree-bg: rgba($gray-color-light, .5);
$footer_bg: #dedede;
$nickname_focus_color: #caced7;
$loginform_color: #908f90;
$loginform_active: #50596c;

// スケジュール
$lesson-color: #c5cae9;
$consulting-color: #c5e1a5;
$self-color: #ffecb3;
$private-color: #cfd8dc;

// Fonts
// Credit: https://www.smashingmagazine.com/2015/11/using-system-ui-fonts-practical-guide/
$base-font-family: -apple-system, system-ui, BlinkMacSystemFont, "Helvetica Neue", Roboto !default;
$mono-font-family: "SF Mono", "Segoe UI Mono", "Roboto Mono", Menlo, Courier, monospace !default;
$fallback-font-family: "Helvetica Neue", sans-serif !default;
$cjk-zh-font-family: $base-font-family, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", $fallback-font-family !default;
$cjk-jp-font-family: $base-font-family, "Hiragino Sans", "Hiragino Kaku Gothic Pro", Meiryo, sans-serif !default;
$cjk-ko-font-family: $base-font-family, "Malgun Gothic", $fallback-font-family !default;
$body-font-family: $cjk-jp-font-family !default;

// Unit sizes
$unit-o: .05rem !default;
$unit-h: .1rem !default;
$unit-1: .2rem !default;
$unit-2: .4rem !default;
$unit-3: .6rem !default;
$unit-4: .8rem !default;
$unit-5: 1rem !default;
$unit-6: 1.2rem !default;
$unit-7: 1.4rem !default;
$unit-8: 1.6rem !default;
$unit-9: 1.8rem !default;
$unit-10: 2rem !default;
$unit-12: 2.4rem !default;
$unit-16: 3.2rem !default;

// Font sizes
$html-font-size: 20px !default;
$html-line-height: 1.5 !default;
$font-size: .8rem !default;
$font-size-xs: .6rem !default;
$font-size-sm: .7rem !default;
$font-size-lg: .9rem !default;
$line-height: 1rem !default;

// Sizes
$layout-spacing: $unit-2 !default;
$layout-spacing-sm: $unit-1 !default;
$layout-spacing-lg: $unit-3 !default;
$border-radius: $unit-h !default;
$border-width: $unit-o !default;
$border-width-lg: $unit-h !default;
$control-size: $unit-9 !default;
$control-size-sm: $unit-7 !default;
$control-size-lg: $unit-10 !default;
$control-padding-x: $unit-2 !default;
$control-padding-x-sm: $unit-2 * .75 !default;
$control-padding-x-lg: $unit-2 * 1.5 !default;
$control-padding-y: ($control-size - $line-height) / 2 - $border-width !default;
$control-padding-y-sm: ($control-size-sm - $line-height) / 2 - $border-width !default;
$control-padding-y-lg: ($control-size-lg - $line-height) / 2 - $border-width !default;
$control-icon-size: .8rem !default;
$control-width-xs: 180px !default;
$control-width-sm: 320px !default;
$control-width-md: 640px !default;
$control-width-lg: 960px !default;
$control-width-xl: 1280px !default;

// Responsive breakpoints
$size-xxs: 360px !default;
$size-xs: 480px !default;
$size-sm: 600px !default;
$size-md: 840px !default;
$size-lg: 960px !default;
$size-xl: 1280px !default;
$size-2x: 1440px !default;
$responsive-breakpoint: $size-xs !default;

// Z-index
$zindex-0: 1 !default;
$zindex-1: 100 !default;
$zindex-2: 200 !default;
$zindex-3: 300 !default;
$zindex-4: 400 !default;
