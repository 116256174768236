// c_btn
// オリジナルボタン

.c_btn {
  padding: $control-padding-y $control-padding-x-lg;
  background: $button-primary-color;
  border-color: $button-primary-color-dark;
  color: $light-color;
  font-size: $font-size-sm;
  height: auto;

  .material-icons {
    font-size: .8rem;
    vertical-align: text-bottom;
    margin-right: $unit-1;
  }

  &:focus {
    @include control-shadow($link-color);
  }

  &:focus,
  &:hover {
    background: lighten($button-primary-color, 10);
    border-color: darken($button-primary-color-dark, 5);
    color: $light-color;
  }

  &:active,
  &.active {
    background: lighten($button-primary-color, 10);
    border-color: darken($button-primary-color-dark, 7);
    color: $light-color;

    &.loading {
      &::after {
        border-bottom-color: $light-color;
        border-left-color: $light-color;
      }
    }
  }

  &.c_btn-s {
    padding: $control-padding-y-sm $control-padding-x-sm;
    font-size: $font-size-xs;
  }

}

%btn-clear {
  background: $light-color;
  border-color: $button-primary-color-dark;
  color: $button-primary-color-dark;

  &:focus,
  &:hover {
    background: lighten($button-primary-color-dark, 45);
    border-color: $button-primary-color;
    color: $button-primary-color;
  }

  &:active,
  &.active {
    background: lighten($button-primary-color-dark, 45);
    border-color: darken($button-primary-color-dark, 5);
    color: $button-primary-color;
  }

  &.loading {
    &::after {
      border-bottom-color: $button-primary-color;
      border-left-color: $button-primary-color;
    }
  }
}

.c_btn-clear {
  @extend %btn-clear;
  .modal-footer & {
    padding: $control-padding-y 0;
    border: none;
    &:focus,
    &:hover,
    &:active,
    &.active {
      box-shadow: none;
      background: transparent;
      border: none;
      text-decoration: underline;
    }
  }
}

.c_btn-utility {
  @extend %btn-clear;
  .modal-footer & {
    padding: $control-padding-y 0;
    border: none;
    &:focus,
    &:hover,
    &:active,
    &.active {
      box-shadow: none;
      background: transparent;
      border: none;
      text-decoration: underline;
    }
  }
}

.c_btn-delete {
  @extend %btn-clear;
  border: none;
  color: darken($error-color, 10);
  padding: 0;

  &:focus,
  &:hover,
  &:active,
  &.active {
    box-shadow: none;
    background: transparent;
    border: none;
    color: darken($error-color, 10);
    text-decoration: underline;
  }
}

.c_btn-close {
  background: transparent;
  border: none;
  font-size: $font-size-xs;
  color: $gray-color;
  height: 1rem;
  padding: 0;

  &:focus,
  &:hover,
  &:active,
  &.active {
    box-shadow: none;
    background: transparent;
    border: none;
    color: $gray-color;
    text-decoration: underline;
  }
}

.c_btn-group {
  display: flex;
  align-items: center;
  justify-content: center;
  .c_btn {
    &:not(:last-child) {
      margin-right: $unit-2;
    }
  }
}

// モーダル内両端揃えボタン
.c_btn-group-modal {
  justify-content: space-between;
}

// 新規予定追加ボタン
.c_btn-schedule-add {
  @include control-transition;
  @include control-shadow($link-color);
  position: fixed;
  bottom: $unit-10;
  right: $unit-5;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: $unit-9;
  height: $unit-9;
  border-radius: 50%;
  background: $light-color;
  border: 1px solid $link-color;
  padding: 0;

  &:active,
  &:focus,
  &:hover {
    background: lighten($link-color, 40);
  }

  @media (max-width: $size-sm) {
    position: fixed;
    bottom: $unit-12;
    right: $unit-3;
  }
}
