// .c_headline
// ヘッドライン・見出しモジュール
// 見出し1相当のフォントサイズを一旦設定しています。
$headeline-margin: -.4rem;
$headeline-tab: 20px;

.c_headline {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 (- $unit-2) $unit-2;
  padding: $unit-1;
  background: $light-color;
  border-bottom: 1px solid lighten($dark-color, 70);

  @media (max-width: $size-sm) {
    position: relative;
  }

  .o_container-main & {
    flex: none;
  }
}// .c_headline

// ヘッドライン内アイコン
.c_headline-icon {
  width: auto;
  height: auto;
  padding: $unit-1;
  color: $dark-color;
  font-size: $font-size-lg;
}

// ヘッドライン内テキスト
.c_headline-title {
  font-size: $font-size-sm;
  font-weight: bold;
  line-height: 1.2;
  margin-right: $unit-1;
}

// 見出し下サブタイトル
.c_headline-sub {
  flex: none;
  font-size: $font-size-sm;
  font-weight: bold;
  padding: 0;
  margin-bottom: .4rem;

  &.c_headline-select {
    display: flex;
    justify-content: flex-end;
  }

  // アバターありの時
  &.has-avatar {
    display: flex;
    align-items: center;
    padding: $unit-2;
    margin-bottom: 0;

    .avatar {
      width: $unit-12;
      height: $unit-12;
      margin-right: $unit-3;
      .material-icons {
        font-size: 2.4rem;
      }
    }
  }

  .c_btn {
    padding: $unit-1 $unit-3;
    font-size: $font-size-xs;
  }
}

// ヘッドライン内リンク
.c_headline-link {
  margin: 0 0 $unit-2;
  font-size: $font-size-xs;

  a {
    display: inline-flex;
    align-items: center;

    .material-icons {
      font-size: $font-size-xs;
      margin-right: $unit-1;
    }
  }
}

.c_headline-group {
  background: $light-color;
  margin-bottom: $unit-2;
  padding: $unit-2 0 0;

  .tab {
    font-size: $font-size-xs;
    margin-bottom: 0;
  }
}
