// ローディング

.bounce-spinner {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: lighten($important-color, 42%);
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;

  -webkit-animation: bounce 1.5s infinite ease-in-out;
  animation: bounce 1.5s infinite ease-in-out;
}

@-webkit-keyframes bounce {
  0%, 100% { -webkit-transform: scale(1.1) }
  50% { -webkit-transform: scale(.9) }
}

@keyframes bounce {
  0%, 100% {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
  } 50% {
    transform: scale(.9);
    -webkit-transform: scale(.9);
  }
}