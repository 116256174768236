// c_tab
// タブ切り替えUI
// メール本文内タブ切り替えリスト

// タブヘッダーリスト
.c_tab-header {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-size: $font-size-sm;
  position: absolute;
  right: 8px;

  @media (max-width: $size-sm) {
    font-size: $font-size-xs;
    position: relative;
    width: 90%;
    right: 0;
    margin: $unit-2 auto 0;
  }

  .c_tab-header-item {
    @include control-transition;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 $unit-3;
    border: 1px solid $dark-color;
    border-right: 0;
    background: $light-color;
    font-size: $font-size-xs;
    color: lighten($dark-color, 5);

    @media (max-width: $size-sm) {
      width: 50%;
      margin-bottom: $unit-1;

      @at-root .p_schedule & {
        width: 33%;
      }

      &:nth-child(2n) {
        @media (max-width: $size-sm) {
          border-right: 1px solid $primary-color;

          @at-root .p_schedule & {
            border-right: none;
          }
        }
      }
    }

    &:last-child {
      border-right: 1px solid $dark-color;
    }

    &:active,
    &:focus,
    &:hover {
      background: lighten($dark-color, 15);
      color: $light-color;
      opacity: .8;
    }

    // アクティブ時
    &.is-active {
      background: $dark-color;
      color: $light-color;
      opacity: .8;
      pointer-events: none;
    }
  }

  .p_student-detail & {
    justify-content: center;

    @media (max-width: $size-sm) {
      justify-content: flex-start;
    }
  }

}

// タブ切り替え本文
.c_tab-wrapper {
  min-height: 300px;
}

// タブ切り替え子要素
.c_tab-item {
  display: none;
  opacity: 0;
  // アクティブ時にフェードイン
  &.is-active {
    display: block;
    animation: fadeIn .3s ease-in-out forwards;
  }
}

// ユーザー情報タブ
.c_tab-item-user {

  // 本文
  .c_tab-main {
    .m_setting {
      padding: 0;
    }
  }

  // 補足
  .c_tab-aside {
    > * {
      &:not(:first-child) {
        margin-top: $unit-2;
      }
    }
  }
}

// レッスンタブ
.c_tab-item-consulting,
.c_tab-item-lesson {
  height: 72vh;
  overflow: hidden;

  .columns {
    height: 100%;
    overflow: hidden;
  }

  .c_tab-main {
    @include scrollbar;
    overflow-y: auto;
  }

  // 補足
  .c_tab-aside {
    padding-right: 0;
    @media (max-width: $size-sm) {
      display: none;
    }
  }
  .c_tab-main {
    overflow: auto;
  }
}
